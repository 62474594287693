<template>
  <StMobileLayoutProvider>
    <div class="container">
      <MobileLayout with-close-button without-footer without-tapbar>
        <slot />
      </MobileLayout>
    </div>
  </StMobileLayoutProvider>
</template>

<script setup lang="ts"></script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}
</style>
